body {
  margin: 0;
}

* {
  font-family: Proxima Nova, sans-serif;
}

html, body, #root {
  height: 100%;
}

ul[class], ol[class] {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #006CB5;
}

p {
  color: #1E1E1E;
  line-height: 22px;
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  max-width: 100%;
}

button {
  padding: 0;
  border: 0;
  background: 0;
}

.text-center {
  text-align: center;
}

.accordion-expand {
  color: #657B8B;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}

.lind-logo svg {
  width: 144px;
}

.folder_icon, .doc_icon {
  width: 20px;
  margin-right: 3px;
  position: relative;
  top: -1px;
}

.arrow_icon {
  transform: rotate(270deg);
  width: 11px;
  margin-right: 13px;
}

.arrow_icon.open {
  transform: none;
}

.accordion-module {
  margin-top: 20px;
}

.accordion_content {
  padding-left: 25px;
  display: none;
}

.accordion a {
  display: block;
  margin-left: 8px;
}

.accordion_content.open {
  display: block;
}

.accordion_title {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 10px;
}

.accordion_title a {
  color: #657B8B;
}

.accordion_title_active a {
  color: #006cb5;
}

.accordion_title a .folder_icon {
  top: 4px;
}

.main-block {
  display: flex;
}

.module {
  margin-bottom: 40px;
}

.module-head .doc_icon {
  top: 3px;
}

.container {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.sidebar {
  width: 0;
  min-width: 0;
  background: #F2F2F7;
  position: relative;
  transition: 0.4s width, 0.4s min-width;
  visibility: hidden;
  min-height: 100vh;
}

.sidebar.open {
  max-width: 18%;
  width: 300px;
  min-width: 240px;
  padding: 15px 30px;
  visibility: visible;
}

.sidebar_content {
  position: sticky;
  top: 20px;
}

.sidebar:not(.open) ~ .main-content .extensions_img_wrap {
  max-height: 250px;
}

.sidebar_control {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background: #006CB5;
  position: absolute;
  left: 0;
  top: 65px;
  cursor: pointer;
  visibility: visible;
  transition: 0.4s left;
}

.sidebar.open .sidebar_control {
  transform: rotate(180deg);
  left: 270px;
}

.sidebar_control svg {
  position: relative;
  top: 2px;
}

.main-content {
  width: 100%;
}

.main {
  padding: 0 55px;
  display: flex;
  gap: 30px;
}

.homepage {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px auto;
}

.homepage div {
  width: 100%;
}

.homepage-content {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

#search-widget {
  margin-block: 35px;
}

.anchor_menu {
  margin-bottom: 30px;
  min-width: 218px;
  position: sticky;
  top: 0;
  /* top: 115px; */
  align-self: flex-start;
}

.anchor_menu_title {
  color: #003366;
  font-weight: 500;
  text-transform: uppercase;
}

.anchor_menu_item {
  display: flex;
  margin-bottom: 15px;
}

.anchor_menu_item a {
  font-size: 14px;
  color: #657B8B;
}

.anchor_menu_item_active > a {
  color: #006CB5;
}

.anchor_menu_item a:hover {
  text-decoration: underline;
}

.anchor_menu_item  > .anchor_menu_list {
  padding-top: 15px;
  padding-left: 15px;
}

.anchor_menu_item  > .anchor_menu_list .anchor_menu_item:last-child {
  margin-bottom: 0;
}

.anchor_menu_item_sub {
  flex-direction: column;
}

.aa-PreviewDescription .anchor_menu_item {
  margin-bottom: 8px;
}

.aa-PreviewDescription .anchor_menu_item a {
  font-size: 13px;
}

.aa-PreviewDescription .anchor_menu_title {
  font-size: 15px;
}

.module_links {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #EEEEF5;
  height: 84px;
  /* position: sticky;
  top: 0;
  z-index: 10;
  background: #fff; */
}

.header_content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.header_link {
  text-transform: uppercase;
  color: #1E293B;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 40px;
  cursor: pointer;
}

.header_link_last {
  margin-left: auto;
}

.header_search_wrap {
  display: flex;
  position: relative;
  width: 62%;
}

.header_search_icon {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 30px;
}

.header_search {
  border: 0;
  border-radius: 30px;
  box-shadow: 0px 2px 6px 0px #1E293B80;
  width: 100%;
  height: 48px;
  text-indent: 70px;
  font-size: 20px;
  color: #1e293b;
}

.header_search:focus {
  outline: 0;
}

.header_search::-webkit-input-placeholder {
  font-size: 20px;
  color: #657B8B;
}

.content_h1 {
  margin: 0 0 40px;
  font-size: 28px;
  color: #003366;
}

.content_h2 {
  font-size: 22px;
  color: #006CB5;
}

.content_h3 {
  display: block;
  font-size: 20px;
  color: #003366;
}

.content_h4 {
  font-size: 18px;
  color: #003366;
  margin-top: 0;
}

.block-style {
  border: 1px solid #eeeef5;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
}

.block-style:last-child, .block-style:only-of-type {
  margin-bottom: 0;
}

article {
  margin-bottom: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid #006cb5;
  position: relative;
}

.block-style article {
  border: 0;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}

.block-style > article:first-child {
  margin-top: 0;
}

.block-style > article:last-child {
  margin-bottom: 0;
}

article h2:first-child {
  margin-top: 0;
}

.see-more {
  font-size: 18px;
  text-decoration: underline;
}

.see-more:hover {
  text-decoration: none;
}

.extensions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.extensions_item {
  width: 48%;
}

.extensions_item img {
  max-width: 100%;
}

.extensions_img_wrap {
  max-height: 170px;
  overflow: hidden;
}

ul.list-style {
  list-style: initial;
  margin-left: 20px;
}

.highlights {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  min-height: 280px;
}

.highlights__item {
  width: 25%;
  min-width: 225px;
  padding: 10px;
  margin-bottom: 0;
}

.highlights__item--img {
  max-height: 135px;
  overflow: hidden;
}

.highlights__item--text {
  margin-top: 10px;
  line-height: 20px;
}

@media (min-width: 1700px) {
  .highlights__item--img {
    max-height: 170px;
  }
}

@media (min-width: 1900px) {
  .highlights__item--img {
    max-height: 205px;
  }
}

@media (min-width: 2100px) {
  .highlights__item--img {
    max-height: 70%;
  }
}

@media (min-width: 3000px) {
  .highlights__item--img {
    max-height: none;
  }
}

.logo {
  width: 215px;
}

.loading {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.login-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-block {
  padding: 3rem;
  border: 1px solid #ccc;
  border-radius: 6px;
}

@media (min-width: 768px) {
  .login-block {
    flex: 0 0 auto;
    width: 35%;
  }
}

.login-header {
  margin-bottom: 3rem;
}

.login-h1 {
  font-size: calc(1.375rem + 1.5vw);
  margin-bottom: .5rem;
}

.login-h2 {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: .5rem;
}

.primary-button {
  cursor: pointer;
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.primary-button:hover {
  background-color: #1565c0;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.primary-button:active {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.header_search_wrap > div {
  width: 100%
}

.search-container {
  margin: 0 auto;
  max-width: 640px;
  width: 100%;
}

.ais-Hits {
  margin-top: 1rem;
}

.ais-Hits-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  padding: 0;
}

.ais-Hits-item {
  background: rgba(128, 126, 163, 0.08);
  border-radius: 5px;
  height: 100%;
  min-height: 202px;
  padding: 1rem;
  width: 100%;
}

.aa-Grid {
  display: grid;
  padding: 0 calc(var(--aa-spacing-half) / 2);
  column-gap: calc(var(--aa-spacing-half) / 2);
  grid-template-columns: 50% 1fr;
}

.aa-MultiGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: calc(var(--aa-spacing-half) / 2);
}

.aa-Column {
  padding: calc(var(--aa-spacing-half) / 2) 0;
}

.aa-PanelLayout {
  padding: 0;
  overflow-x: hidden;
}

.aa-Detached .aa-DetachedContainer {
  background: none;
}

.aa-DetachedContainer .aa-DetachedFormContainer {
  background: rgba(
    var(--aa-background-color-rgb),
    var(--aa-background-color-alpha)
  );
  padding: calc(var(--aa-spacing-half) * 1.5);
}

div.aa-Panel--scrollable {
  max-height: 425px;
}

@media (min-width: 1200px) {
  div.aa-DetachedContainer--modal {
    max-width: 1040px;
  }
}

.aa-DetachedContainer .aa-Preview {
  display: none;
  padding: 20px 12px;
  max-height: 500px;
  overflow: auto;
}

.aa-DetachedContainer--modal .aa-Preview {
  display: block;
}

.aa-PreviewImage {
  display: flex;
  justify-content: center;
  height: 150px;
  margin-bottom: var(--aa-spacing-half);
  padding: var(--aa-spacing-half);
  background: var(--aa-background-color);
  border: 1px solid var(--aa-selected-color);
  border-radius: 3px;
}

.aa-PreviewImage img {
  max-width: 100%;
  object-fit: contain;
}

.aa-PreviewTitle {
  margin-bottom: var(--aa-spacing-half);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.aa-PreviewPrice {
  margin-bottom: var(--aa-spacing-half);
  color: var(--aa-icon-color);
  font-weight: bold;
}

.aa-PreviewDescription {
  max-width: 100%;
  padding: 0.3em 0;
  font-size: 0.85em;
  color: var(--aa-content-text-color);
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 20px;
}

.aa-PreviewDescription__content, .PreviewDescription__content--Clone {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 30px;
}

.aa-PreviewDescription__content:empty {
  display: none;
}

.aa-PreviewTitle mark,
.aa-PreviewDescription mark {
  background: none;
  color: var(--aa-primary-color);
}

mark {
  font-weight: 700;
  text-decoration: underline;
}

[data-autocomplete-source-id='hits'] {
  margin-bottom: var(--aa-spacing-half);
}

[data-autocomplete-source-id='suggestions'] .aa-List {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(calc(var(--aa-spacing-half) / 2) * -1px);
}

[data-autocomplete-source-id='suggestions'] .aa-Item[aria-selected='true'] {
  background: none;
}

.aa-QuerySuggestion {
  display: inline-block;
  margin: calc(calc(var(--aa-spacing-half) / 2) / 2);
  padding: var(--aa-spacing-half) var(--aa-spacing);
  color: rgba(var(--aa-icon-color-rgb), var(--aa-icon-color-alpha));
  font-size: 0.85em;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(
    var(--aa-panel-border-color-rgb),
    var(--aa-panel-border-color-alpha)
  );
  border-radius: 3px;
}

.aa-QuerySuggestion mark {
  background: none;
  font-weight: bold;
  color: currentColor;
}

[data-autocomplete-source-id='suggestions']
  .aa-Item[aria-selected='true']
  .aa-QuerySuggestion {
  background: rgba(
    var(--aa-selected-color-rgb),
    var(--aa-selected-color-alpha)
  );
  border-color: transparent;
}

.aa-SubmitIcon {
  fill: #006cb5;
}

form.aa-Form:focus-within, .aa-DetachedSearchButton:focus {
  border-color: #006cb5 !important;
  box-shadow: 0 0 0 2px rgba(0, 108, 181, 0.2), inset 0 0 0 2px rgba(0, 108, 181, 0.2) !important;
}

.breadcrumbs {
  margin-top: 3px;
  font-size: 13px;
  color: #666;
}

li.aa-Item {
  padding: 0;
}

li.aa-Item[aria-selected='true'] {
  background: none;
}

li.aa-Item .aa-ItemLink {
  padding: 8px;
}

.aa-ItemLink:hover {
  background-color: #006CB5;
  color: #fff;
  border-radius: 4px;
}

.aa-ItemLink:hover .breadcrumbs {
  color: rgba(255, 255, 255, 0.7);
}

.aa-ItemContent mark {
  text-decoration: underline;
}

.aa-ItemLink:hover mark {
  color: #fff;
  font-weight: normal;
}

li.aa-Item:empty {
  display: none;
}

ul.aa-PreviewTags {
  display: flex;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 20px;
}

.aa-PreviewTags__item:not(:last-child):after {
  content: ', '
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.link-wrap {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 400px;
  margin-left: 5px;
}

.gear-list {
  margin-bottom: 15px;
}

.gear-link {
  margin-bottom: 5px;
}

.tag {
  font-size: 14px;
  margin-left: 10px;
  padding: 5px 8px;
  background: #006cb5;
  color: #fff;
  border-radius: 8px;
  text-transform: uppercase;
}

.module-section {
  flex-grow: 1;
}

.assets-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.asset-wrap {
  max-width: 32.5%;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.accordion-module {
  overflow: auto;
  height: 85vh;
  scrollbar-width: thin;
}

.edit-date {
  text-align: right;
  margin-bottom: 10px;
  font-size: 14px;
  color: #657b8b;
  padding-left: 12px;
}

.flex-between-centered {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-between-centered .edit-date {
  margin-bottom: 0;
}

.deviation-block {
  margin-bottom: 40px;
  background: #b45959;
  color: #fff;
  padding: 20px;
  font-size: 18px;
  line-height: 29px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.max-w-none {
  max-width: none;
}

table {
  border-collapse: collapse;
}

td, th {
  padding-inline: 10px;
  border: 1px solid #eeeef5;
}

th {
  background: #f0f3f5;
  border-color: #c3d3dd;
}

.news-item {
  margin-bottom: 20px;
}

.news-item .content_h3 {
  margin-bottom: 0;
}

.news-item__header {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.news-item__icon {
  color: #003366;
  font-size: 25px;
  width: 15px;
  margin-right: 8px;
}

.news-item__content {
  max-height: 0;
  overflow: hidden;
  transition: 0.4s;
}

.news-item__content.open {
  max-height: 20000px;
}

.news-item .edit-date {
  padding-left: 0;
}

.homepage .aa-InputWrapperPrefix,
.homepage .aa-InputWrapperSuffix {
  width: auto;
}